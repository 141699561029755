.imgContainer {
  width: 30%;
  display: flex;

  justify-content: space-around;
}
.img {
  height: 34px;
  width: 34px;
}

.img:hover {
  transform: scale(1.1);
}
.container {
  margin-top: 2em;
  display: flex;
  width: 100%;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 2em;
}
