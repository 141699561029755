body,
html {
  background-color: black;
  color: #dddddd;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
}

h1 {
  color: white;
}
a {
  color: #dddddd;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: white;
}
