.container {
  display: flex;
  position: relative;
  padding: 3px;
  max-width: "350px";
}

.titleBox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  max-width: 303px;
  height: 2em;
  background-color: rgb(11, 10, 10, 0.8);
  bottom: 0px;
  left: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  padding: 4px;
}
.title {
  color: white;
  align-self: center;
  opacity: 1;
  font-size: large;
}

.titleBox2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  background-color: rgb(11, 10, 10, 0.02);
  max-width: 100%;
  height: 8em;
  bottom: 0px;
  left: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  padding: 4px;
  max-width: 300px;
}
.title2 {
  color: white;
  align-self: center;
  opacity: 1;
  max-width: 90%;
  text-align: center;
  font-size: large;
}

.title3 {
  color: white;
  opacity: 3;
  max-width: 90%;
  text-align: center;
  font-size: x-large;
  text-transform: capitalize;
  font-weight: bold;
}
.container:hover {
  opacity: 1;
  transform: scale(1.02);
  cursor: pointer;
}
