.container {
  display: flex;
  padding: 2em;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ebb419;
  width: 100%;
  min-width: 0;
  height: fit-content;
  border-radius: 6px;
  margin-bottom: 2em;
  flex-wrap: wrap;
  color: black;
  flex-shrink: 0.1;
}
