@font-face {
  font-family: "Futura";
  src: url("./assets/fonts/Futura.ttf");
}
@font-face {
  font-family: "FuturaBook";
  src: url("./assets/fonts/FuturaBook.ttf");
}
p {
  font-family: "FuturaBook";
}
label {
  font-family: "FuturaBook";
}
span {
  font-family: "FuturaBook";
}
h2 {
  font-family: "Futura";
}

h3 {
  font-family: "Futura";
}
h4 {
  font-family: "Futura";
}

h1 {
  font-family: "Futura";
  text-transform: uppercase;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "courier", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading-text {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.heading-text span {
  font-weight: 100;
}

/* Responsive image gallery rules begin*/

.image-gallery {
  /* Mobile first */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-gallery .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-item img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .image-gallery {
    flex-direction: row;
  }
}

/* overlay styles */

.image-item {
  position: relative;
  cursor: pointer;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transition: all 0.2s 0.1s ease-in-out;
  color: #fff;
  /* center overlay content */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* hover */
.image-item:hover .overlay {
}
