@import "../../../variables.modules.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 9000;
  padding-top: 1em;
  padding-left: 2em;
}

.link {
  margin-left: 1em;
}

.sideMenuWrapper {
  display: flex;
  justify-self: flex-end;
  align-self: center;
  align-items: center;
  align-content: center;
}

.linksContainer {
  margin: 2em;
  display: flex;
  padding: $minPadding;
  align-items: center;
  justify-content: space-around;
}

.img {
  min-width: 100px;
  width: 25%;
  height: 25%;
}

.imgMobile {
  min-width: 100px;
  width: 40%;
  height: 40%;
  display: flex;

  align-self: center;
  justify-self: center;
}
.logo:hover {
  cursor: pointer;
}
