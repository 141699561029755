.NavBar {
  /*  width: 100%;*/
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.NavItems {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  margin-right: 5%;
}

.menuVisible {
  visibility: visible;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  margin-right: 5%;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 2em;
  top: 1.5em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #dddddd;
  border-radius: 12px;
  justify-self: center;
  align-self: center;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/* General sidebar styles */
.bm-menu {
  position: absolute;
  right: 0px;
  background: black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  max-height: 60%;
  border-color: rgb(255, 217, 0);
  border-width: 1px;
  border-style: groove;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: -6px 6px 11px 2px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: -6px 6px 11px 2px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: -6px 6px 11px 2px rgba(0, 0, 0, 0.67);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  font-size: 1em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.burgerVisible {
  visibility: hidden;
}

.bm-menu-wrap {
  max-width: 40%;
  margin-top: 50px;
}

.menu-items {
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
  align-self: center;
  justify-self: center;
}
.side-menu-container {
  flex-wrap: wrap;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .burgerVisible {
    visibility: visible;
  }
  .menuVisible {
    visibility: hidden;
  }
}
