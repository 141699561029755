.container {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  margin-top: 100px;
}

.textWrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.aboutBox {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10;
  flex-direction: column;
}
.img {
  width: 60%;
  height: auto;
  justify-self: center;
  margin-bottom: 80px;
  margin-top: 40px;
  border-radius: 4px;
}

.text {
  text-align: center;
}
