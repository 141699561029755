.cardsBox {
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  justify-self: center;
}
.container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 20%;
  padding-left: 20%;
}
.titleBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.title {
  margin-bottom: 2em;
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
}
.embedContainer {
  position: relative;
  padding-bottom: 75%;
  height: auto;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  display: flex;
}
.embedContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
