.mwLogo {
  height: 2em;
  width: 2em;
  margin-right: 2em;
  display: flex;
}

.container {
  align-items: center;

  padding: 1em;
  height: 2.2em;
  display: flex;
  width: 100%;
  background-color: black;
  justify-content: flex-end;
  flex-direction: row;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  position: fixed;
  bottom: 0pt;
  left: 0pt;
}
